function initMap() {
    const mapSection = document.querySelector(".section-map")

    if(mapSection) {
        // The location of San Jose
        const sanJose = { lat: 37.335480, lng: -121.893028 };

        // The map, centered at San Jose
        const map = new google.maps.Map(document.querySelector("#map"), {
            zoom: 8.2,
            center: sanJose,
        });

        // Region LatLng coordinates.
        const regionCoords = [
            { lat: 37.814880, lng: -122.516671 },
            { lat: 37.819818, lng: -122.314999 },
            { lat: 37.465667, lng: -121.832043 },
            { lat: 36.841147, lng: -121.509187 },
            { lat: 36.555975, lng: -121.923151 },
            { lat: 37.158602, lng: -122.425226 },
            { lat: 37.738990, lng: -122.531486 },
            { lat: 37.814880, lng: -122.516671 },
        ];

        // Construct the polygon.
        const regionHiglight = new google.maps.Polygon({
            paths: regionCoords,
            strokeColor: "#094785",
            strokeOpacity: 0.8,
            strokeWeight: 0,
            fillColor: "#094785",
            fillOpacity: 0.35,
        });

        // Set polygon to map
        regionHiglight.setMap(map);
    }

}

export default function lazyLoadScripts(){

    function updateScriptsInView() {
        // Get all the sections with the class "section-map"
        const sections = document.querySelectorAll('.section-map');

        // Calculate the buffer value (1 viewport's worth)
        const buffer = window.innerHeight;

        // Iterate over each section
        sections.forEach(section => {
            // Calculate the section's position relative to the viewport
            const rect = section.getBoundingClientRect();

            // Check if the section is within the viewport with the buffer
            if (rect.top < window.innerHeight + buffer) {
                // Find all scripts with the data attribute "data-src" within the section
                const scripts = section.querySelectorAll('script[data-lz-src]');

                // Iterate over each script and flip the data-src to the actual src
                scripts.forEach(script => {
                    script.src = script.dataset.lzSrc;
                    script.removeAttribute('data-lz-src');
                });
            }
        });

    }

    updateScriptsInView();

    // Add an event listener to the scroll event
    window.addEventListener('scroll', updateScriptsInView);
}

window.initMap = initMap