import { Modal } from 'bootstrap'

export default function modal() {
	let modals = document.querySelectorAll('.modal')

	if (modals.length) {
		for (var i = 0; i < modals.length; i++) {
			new Modal(modals[i])
		}
	}

	var bookModalBtns = document.querySelectorAll(
		"[data-bs-target='#popup-book-now']"
	)

	var productModalBtns = document.querySelectorAll(
		"[data-bs-target='#popup-product-enquiry']"
	)

	var bookModal = document.getElementById('popup-book-now')
	var productModal = document.getElementById('popup-product-enquiry')

	if (bookModalBtns) {
		bookModalBtns.forEach((btn) => {
			btn.addEventListener('click', function () {
				productModal.style = 'display: none !important'
				bookModal.style.display = 'flex'
			})
		})
	} else {
		bookModal.remove()
	}

	if (productModalBtns) {
		productModalBtns.forEach((btn) => {
			btn.addEventListener('click', function () {
				bookModal.style = 'display: none !important'
				productModal.style.display = 'flex'

				// Dynamic product name for modals
				productModal.querySelector('.modal-title').innerText = `${btn.closest('section').querySelector('h2').innerText} Enquiry`
			})
		})
	} else {
		productModal.remove()
	}
}
