export default function formValidateText() {
	// Pull in all fields that have or include 'name' as a value for the name data attribute
	const inputsTextFields = document.querySelectorAll("[data-name='fullname']")
	const inputsTextFields2 = document.querySelectorAll("[data-name='name']")
	const inputsTextFields3 = document.querySelectorAll(
		"[data-name='referralName']"
	)

	// Merge inputs into a single array
	const allInputs = [
		...inputsTextFields,
		...inputsTextFields2,
		...inputsTextFields3,
	]

	if (allInputs.length != 0) {
		// Iterate through inputs and prevent numbers from being entered
		allInputs.forEach((input) => {
			input.addEventListener('keypress', function (event) {
				let key = event.keyCode || event.which

				let keyChar = String.fromCharCode(key)

				let regex = /[0-9]/
				if (regex.test(keyChar)) {
					event.preventDefault()
				}
			})
		})
	}
}
