export default function scrollNext() {
    const heroAltSection = document.querySelector(".section-hero-alt");

    if(heroAltSection) {
        const nextSection = heroAltSection.nextElementSibling;

        if(nextSection){
            let nextSectionH2 = nextSection.querySelector("h2");
            
            if(nextSectionH2){
                nextSectionH2.setAttribute('id', 'scroll-to');
            }else{
                let nextSectionH3 = nextSection.querySelector("h3");

                if(nextSectionH3){
                    nextSectionH3.setAttribute('id', 'scroll-to');
                }
            }
        }
    }
}