import { accordion } from './components/accordion'
import { swiperDesktop, swiperMobile } from './components/swiper'
import { hamburger, mobileHeader, stickyHeader } from './components/header'
import { viewAnimation, smoothScroll } from './util/animation'
import { outputMonth, countDown } from './util/dates'
import { inlineVideo } from './util/video'
import { phoneConcat, readMore } from './util/text'
import { cf7Redirect } from './util/window'
import collapsible from './components/collapsible'
import modal from './components/modal'
import scrollNext from './util/scroll-next'
import lazyLoadScripts from './components/map'
import formValidateText from './util/formValidation'
;(function ($) {
	$(window).on('load', function () {
		collapsible()
		modal()
		mobileHeader()
		stickyHeader()
		hamburger()
		outputMonth()
		viewAnimation()
		accordion()
		swiperDesktop()
		swiperMobile()
		smoothScroll()
		inlineVideo()
		phoneConcat()
		scrollNext()
		readMore()
		lazyLoadScripts()
		formValidateText()
	})
})(jQuery)
