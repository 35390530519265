
export function phoneConcat() {
    setTimeout(function () {
        $('.phone-text em').each(function () {
            var unsliced = $(this).text();
            var sliced = unsliced.slice(0, -2) + "...";
            $(this).text(sliced);
            var linked = "tel:" + unsliced.replace(/\s/g, '');
            $(this).click(function () {
                if ($(window).width() < 1000) {
                    window.location.href = linked;
                } else {
                    $(this).text(unsliced);
                }
            });
        });
    
    }, 3000);
}

export function readMore() {
    let readMoreButtons = document.querySelectorAll('.readmore-initial, .readmore-less');

    readMoreButtons.forEach(function(el, index){

            let closestSection = el.closest('section');
            let closestReadMoreDiv = closestSection.querySelector('.seo-readmore');

        el.addEventListener('click', function(e){
            e.preventDefault();

            if(closestReadMoreDiv){
                closestSection.classList.toggle('readmore-active');
            }
        });
    });
}